const appConfig = {
    pathUserTemplate: 'https://eventqiu.com/assets/template/funvention_user_template.xlsx',
    apiPrefix: 'https://funvention-api.eventqiu.com/api',
    authenticatedEntryPath: '/admin-eventqiu/user',
    unAuthenticatedEntryPath: '/admin-eventqiu/sign-in',
    adminPath: '/admin-eventqiu',
    tourPath: '/',
    notFound: '/not-found',
    locale: 'en',
    enableMock: false,
}

export default appConfig

